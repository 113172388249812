#education {
    .education-grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        gap: 20px;
        padding: 0 20px;
        max-width: 1200px;
        margin: 0 auto;
    }

    .education-card {
        background: white;
        /* Cards claros como elementos da timeline */
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        text-align: left;

        h2,
        h3,
        p {
            color: rgb(39, 40, 34);
            /* Cores escuras nos cards */   
        }

        h2 {
            font-size: 1.5rem;
            margin-bottom: 10px;
        }

        h3 {
            font-size: 1.2rem;
            margin-bottom: 10px;
        }

        p {
            font-size: 1rem;
        }
    }
}

@media screen and (max-width: 1170px) {
    #education {
        .education-grid {
            grid-template-columns: 1fr;
        }

        /* Ajustes de cor para modo mobile (se necessário) */
        .education-card {

            h2,
            h3,
            p {
                color: rgb(39, 40, 34);
                /* Mantém cores escuras mesmo em mobile */
            }
        }
    }
}

/* Adicionado para consistência com a timeline */
@media screen and (max-width: 768px) {
    #education {
        padding: 30px 0;

        h1 {
            font-size: 2rem;
        }
    }
}